@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.footer {
  padding: 12px 24px;
  background: rgb(252,196,29);
  color: black;
  font-size: 10px;
  font-weight: 500;
}

.login-button {
  width: 120px;
}

.splash {
  margin-top: -10%;
  font-size: 14px;
  padding-bottom: 30px;
  background: white;
  border-bottom: 2px solid rgb(252,196,29);
  text-align: center;
}

.splash-content {
  margin-top: -100px;
  height: 200px;
}

.splash-content button{
  margin-top: 20px;
}

.splash .icon {
  margin: 20px;
  transform: scale(1.75);
  filter: invert(.85);
}

.logo {
  height: auto;
  width: 100%;
  max-width: 640px;
  border-radius: 4px;
}

@media only screen and (max-width: 667px) {
  .splash {
    margin-top: -15%;
    font-size: 12px;
  }  
  .splash img{
    margin: 20px 5px;
  }
}

.splash-container {
  height: 100vh;
  padding: 0 20px;
}

.splash-description {
  text-align: center;
  padding-top: 10px;
}

.splash-description img{
  transform: scale(1.25);
  filter: invert(.85);

}


.logo-container {
  text-align: center;
  margin: 200px 45%;
}

.empty-container {
  width: 100%;
  margin: 100px 0;
  text-align: center;
}

.trophy-spin {
  animation: rotation 2s infinite linear;
  margin: 20px 0;
}

.league-logo {
  float: left;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.logoContainer {
  display: inline;
  filter: invert(.85);
}

.file-upload-button {
  width: 120px;
  background-color: #F8D94F;
  border: 1px solid white;
  color: #1a1a1a;
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
/* .ant-menu-item-selected .logoContainer {
  fill: red !important;
} */
.ProjectEditButton {
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 9999;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
  text-align: left;
  min-height: 100vh !important;
}

.site-card-border-less-wrapper {
  background: #ececec;
  padding: 30px;
}

.ant-tabs-nav-container {
}

.ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.avatar-uploader > .ant-upload {
  width: 50%;
  height: 200px;
  margin: 20px 25%;
}

.ant-result {
  padding: 10px 32px 32px 32px;
}

.ant-result-title {
  font-weight: 100;
  font-size: 20px;
}

.ant-list-item-meta-description {
}

.ant-layout {
  background: #fff;
}

.ant-layout-sider-dark {
  border-right: 1px solid #F8D94F;
  background: #2b2b2b;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #f0f2f5;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected span {
  color: rgba(0,0,0, .95);
}

.ant-layout-sider-zero-width-trigger {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #2b2b2b;
  top: 42px;
}

.ant-layout-sider-zero-width-trigger:hover {
  background: #F8D94F;
}

.add-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 50px;
}

.online-tag {
  font-size: 10px;
  margin-left: 5px;
  border-left: 3px solid #87D765;
  box-shadow: 1px 1px 2px #87D765;
}

.offline-tag {
  font-size: 10px;
  margin-left: 5px;
  border-left: 3px solid red;
  box-shadow: 1px 1px 2px red;
}

.remove-member {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
}

.league-info small{
  font-size: 18px;
  font-weight: 300;
  padding: 0 20px;
}

.straw-button {
  margin-top: 25px;
}

.straw-member {
  background: #F8D94F;
  animation: flash 3s normal linear;
}

@keyframes flash {
  0% {
    background: transparent;
  }
  20% {
    background: #F8D94F;
  }
  40% {
    background: transparent;
  }
  60% {
    background: #F8D94F;
  }
  80% {
    background: transparent;
  }
  100% {
    background: #F8D94F;
  }
}

@media only screen and (max-width: 667px) {
  .league-info {
    text-align: center;
  }
  .league-info small{
    display: block;
    margin-bottom: 20px;
  }
  .remove-member {
    bottom: 5px;
    right: 5px;
  }
  .straw-button {
    font-size: 10px;
  }  
}

.bet-amount {
  position: absolute;
  left: 50%;
  width: 40px;
  margin-left: -20px;
  text-align: center;
  top: 15px;
}

.in-bet {
  border-radius: 4px;
  border: 3px solid white;
}

.winner {
  border: 3px solid #87D765;
  background: #87D765;
}

.due-container {
  position: relative;
  text-align: center;
  margin-top: 30px;
}

.due-info, .bet-info {
  width: 50%;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.due-info p, .bet-info p{
  display: inline-block;
  text-align: center;
}

.due-amount {
  position: absolute;
  left: 50%;
  width: 100px;
  margin-left: -45px;
  text-align: center;
  top: -10px;
}

.due-amount svg{
  font-size: 16px;
  margin-top: 10px;
}

.due-tag {
  display: block;
  width: auto;
  font-size: 16px;
  padding: 5px;
  overflow: auto;
}

.payer-avatar {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  border: 3px solid white;
  border-radius: 100%;
}

.paying {
  border: 3px solid #87D765;
}

.message-delete {
  float: right;
  z-index: 9999;
  cursor: pointer;
  margin-top: 12px;
}

.ant-card-head-title p {
  margin: 0;
}
.chat .ant-list{
  position: relative;
  height: auto;
  max-height: 65vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

.ant-comment {
  position: sticky;
}
