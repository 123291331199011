
.league-card {
  margin: 10px 0;
  border: 3px solid #2b2b2b;
  border-radius: 5px;
  min-height: 200px;
  height: auto;
}

.league-card .ant-card-head {
  border-bottom: 3px solid #2b2b2b;
  background: #2b2b2b;
  color: white;
}

.league-card .ant-card-head h3 {
  color: white;
}

.league-card-header {
  height: 36px;
}

.league-card-header img {
  border-radius: 9999px;
  border: 3px solid white;
}

.league-card h1 {
  margin: 10px 0;
  font-size: 24px;
}

.league-card p {
  margin: 10px 0;
  font-size: 14px;
}


.league-card .date-text {
  white-space: pre-line;
  font-weight: bolder;
}

.league-card .location-text {
  white-space: pre-line;
}

.league-card .card-text {
  padding: 10px;
  vertical-align: top;
  background: #FCC41D;
  white-space: pre-line;
  font-size: 14px;
  font-weight: 500;
}


.league-card .due-text {
  padding: 10px;
  vertical-align: top;
  background: #87D765;
  white-space: pre-line;
  float: left;
  font-size: 20px;
  font-weight: 500;
}

.league-card .due-arrow {
  padding: 10px;
  transform: rotate(90deg) scale(2.5);
  position: absolute;
  top: 30px;
  right: 5px;
  color: #87D765;
}
.league-card button {
  margin: 8px 23px;
}

.league-card span {
  margin: 0 10px;
}

.league-card .yeas, .nays {
  display: inline-block;
  height: auto;
  cursor: pointer;
  margin: 0 20px;
}

.league-card .right-actions {
  text-align: right;
  display: block;
  width: 100%;
  position: relative;
}

.league-card .right-actions button img{
  margin-top: -3px;
  transform: scale(1);
}

.league-card .options {
  text-align: center;
  display: block;
  width: 100%;
}

.league-card .option {
  text-align: center;
  display: inline-block;
  width: 45%;
  margin: 10px 25px 10px 0;
}


@media only screen and (max-width: 667px) {
  .league-card .value-text {
    display: block;
    width: 100%;
  }

  .league-card .due-text {
    display: block;
    width: 100%;
  }
  .league-card .due-arrow {
    top: 90px;
  }

  .league-card .option {
    display: block;
    width: 100%;
  }
}


.actions a{
  margin-left: 5px;
  font-size: 12px;
}


